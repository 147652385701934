<script setup lang="ts">
import { AnwCarouselLawyers, AnwCardLawyer } from '@anwalt.de/tailwind-design-system';
import SectionHeadline from '@/shared/components/SectionHeadline.vue';
import { useColleagueListStore } from '@/modules/profile-page/stores/ColleagueListStore';
import { GenderHelper } from '@/shared/helper/GenderHelper';
import { useI18n } from 'vue-i18n';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';
import { statisticKey } from '@/shared/constants/ProviderKeys';
import { computed, inject } from 'vue';
import { COUNTER_TYPES } from '@shared/types/statistic/CounterType';
import { useUserStore } from '../../stores/UserStore';
import { storeToRefs } from 'pinia';
import { TranslationHelper } from '@/shared/helper/TranslationHelper';

const { t } = useI18n();
const { userId } = storeToRefs(useUserStore());
const { transformedColleagueList, colleagueList } = storeToRefs(useColleagueListStore());
const { getTranslationByProductId, getGenderValueForTranslation } = TranslationHelper();

const { colleaguesSpecification } = GenderHelper();

defineProps<{
  showCompanyLawyers: boolean;
}>();

const breakpoints = {
  0: { itemsToShow: 1 },
  300: { itemsToShow: 1.75 },
  420: { itemsToShow: 2.5 },
  540: { itemsToShow: 3 },
  768: { itemsToShow: 4 },
};

const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();
updateAttributeGA({
  [GA_ATRIBUTES.LABEL]: `${t('eventLabelProfile')} ${t('eventLabelHeader')}`,
  [GA_ATRIBUTES.ACTION]: t('eventActionClickProfilePhotoInCompany'),
});

const statistic = inject(statisticKey);
const triggerCounter = () => {
  statistic?.sendCounter({
    counterType: COUNTER_TYPES.COUNTER_TYPE_LAW_OFFICE_RELATED_PROFILES,
    entityId: userId.value,
  });
};
const heading = computed(() => colleaguesSpecification());
</script>

<template>
  <div v-if="colleagueList.length && showCompanyLawyers">
    <SectionHeadline class="!border-b-0" :headline="heading" tag="h3" :counter="colleagueList.length" />
    <AnwCarouselLawyers
      @click="triggerCounter"
      :data-attributes="dataAttributesGA"
      :content="transformedColleagueList(175)"
      :breakpoints="breakpoints"
      :items-to-show="1"
      class="md:hidden" />
    <ul class="hidden md:grid grid-cols-2 gap-4">
      <li v-for="colleague in transformedColleagueList(70)" :key="colleague.id">
        <AnwCardLawyer
          @click="triggerCounter"
          :data-attributes="dataAttributesGA"
          :uri="colleague.uri"
          :imageUri="colleague.imageUri"
          :imageAlt="`${getTranslationByProductId('profileImage', 'lawyer', {
            n: getGenderValueForTranslation(colleague.sex),
          })} ${colleague.fullName}`"
          :linkTitle="`${getTranslationByProductId('companyLawyerTitle', 'lawyer', {
            n: getGenderValueForTranslation(colleague.sex),
          })} ${colleague.fullName}`"
          :sex="colleague.sex"
          :fullName="colleague.fullName"
          :ratingAverage="colleague.ratingAverage"
          :ratingCount="colleague.ratingCount"
          :practice-areas="colleague.practiceAreas"
          :lawyer-title="colleague.lawyerTitle"
          appearance="horizontal"
          :hide-rating="!colleague.isRateable"
          class="!w-full !h-full" />
      </li>
    </ul>
  </div>
</template>
