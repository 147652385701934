<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { AnwReadMoreExternal, AnwRatingCount } from '@anwalt.de/tailwind-design-system';
import { useCompanyDetailsStore } from '@/modules/profile-page/stores/CompanyDetailsStore';

import { useImage } from '@shared/composables/image';
import { COMPANY } from '@shared/constants/ProfileProductId';
import { RATING_LINK } from '@shared/constants/Links';
import { useGoogleAnalytics } from '@/shared/composables/googleAnalytics';
import { GA_ATRIBUTES } from '@/shared/constants/GoogleAnalytics';

const { companyDetails } = useCompanyDetailsStore();

const { t } = useI18n();

const href = computed<string>(() => companyDetails?.uri || '');
const text = computed<string>(() => companyDetails?.about || '');

const imageUrlHost = import.meta.env.VITE_IMAGES_URL;
const { computedProfileImagePath } = useImage(imageUrlHost, companyDetails?.imagePath || '', COMPANY);

const { updateAttributeGA, dataAttributesGA } = useGoogleAnalytics();

updateAttributeGA({
  [GA_ATRIBUTES.LABEL]: t('eventLabelBlock'),
});

console.log(dataAttributesGA);
</script>

<template>
  <div class="grid gap-4 py-4" :class="{ 'border-b': text }">
    <div class="flex flex-wrap justify-start gap-4 pb-4 border-b">
      <figure class="self-center">
        <a :href="href">
          <img
            :src="computedProfileImagePath(600, 48)"
            class="sm:!h-12"
            :alt="companyDetails?.name"
            v-bind="{ ...dataAttributesGA, [GA_ATRIBUTES.ACTION]: t('eventActionClickLinkCompanyLogo') }" />
        </a>
      </figure>
      <span>
        <a
          class="font-semibold"
          :href="href"
          v-bind="{ ...dataAttributesGA, [GA_ATRIBUTES.ACTION]: t('eventActionClickLinkCompanyName') }">
          {{ companyDetails?.name }}</a
        >
        <AnwRatingCount
          v-if="companyDetails?.isRateable"
          v-bind="{ ...dataAttributesGA, [GA_ATRIBUTES.ACTION]: t('eventActionClickLinkRating') }"
          :rating-count="companyDetails?.ratingCount"
          rating-variant="withoutMaxRating"
          :rating-average="companyDetails?.ratingAverage || 0"
          :rating-url="`${href}${RATING_LINK}`"
          :has-inline-rating="true"
          layout-class="flex flex-wrap gap-x-2"
          :number-of-ratings="companyDetails?.ratingCount"
          class="hover:!underline" />
      </span>
    </div>
    <div v-if="text" class="pt-4">
      <AnwReadMoreExternal
        :attribute-GA="{ ...dataAttributesGA, [GA_ATRIBUTES.ACTION]: t('eventActionClickLinkCompanyPage') }"
        :target-blank="false"
        :preview-length="520"
        class="anw-wysiwyg line-clamp-5"
        :href="href"
        :text="text"
        :show-follow-link="!!href"
        :label-follow-link="t('companyReadMore', { companyName: companyDetails?.name })"
        :title="companyDetails?.name" />
    </div>
  </div>
</template>
